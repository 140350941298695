import * as React from "react"
import fetch from "isomorphic-fetch"
import Client from "shopify-buy"

const client = Client.buildClient(
  {
    domain: process.env.GATSBY_SHOPIFY_STORE_URL,
    storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
  },
  fetch
)

const defaultValues = {
  cart: [],
  isOpen: false,
  loading: false,
  onOpen: () => {},
  onClose: () => {},
  addVariantToCart: () => {},
  removeLineItem: () => {},
  updateLineItem: () => {},
  client,
  checkout: {
    lineItems: [],
  },
}

export const StoreContext = React.createContext(defaultValues)

const isBrowser = typeof window !== `undefined`
const localStorageKey = `shopify_checkout_id`
const productImagesLocalStorageKey = `product_images`

export const StoreProvider = ({ children }) => {
  const [checkout, setCheckout] = React.useState(defaultValues.checkout)
  const [loading, setLoading] = React.useState(false)
  const [didJustAddToCart, setDidJustAddToCart] = React.useState(false)
  const [addedProduct, setAddedProduct] = React.useState(null)
  const [addedProductData, setAddedProductData] = React.useState(null)
  const [productImages, setProductImages] = React.useState({})

  const setCheckoutItem = (checkout) => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout.id)
    }

    setCheckout(checkout)
  }

  React.useEffect(() => {
    const initializeCheckout = async () => {
      const existingCheckoutID = isBrowser
        ? localStorage.getItem(localStorageKey)
        : null

      if (isBrowser) {
        const productImages = localStorage.getItem(productImagesLocalStorageKey)
        if (productImages) {
          setProductImages(JSON.parse(productImages))
        }
      }

      if (existingCheckoutID && existingCheckoutID !== `null`) {
        try {
          const existingCheckout = await client.checkout.fetch(
            existingCheckoutID
          )
          if (!existingCheckout.completedAt) {
            setCheckoutItem(existingCheckout)
            return
          }
        } catch (e) {
          localStorage.setItem(localStorageKey, null)
        }
      }

      const newCheckout = await client.checkout.create()
      setCheckoutItem(newCheckout)
    }

    initializeCheckout()
  }, [])

  const addVariantToCart = (variantId, quantity, product, price, image, variantShopifyId) => {
    setLoading(true)

    const checkoutID = checkout.id

    let newProductImages = {
      ...productImages
    }

    if (image) {
      newProductImages[variantShopifyId] = image
    }

    setProductImages(newProductImages)

    if (isBrowser) {
      localStorage.setItem(productImagesLocalStorageKey, JSON.stringify(newProductImages))
    }

    const lineItemsToUpdate = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
        customAttributes: [{ key: "ID", value: variantShopifyId }],
      },
    ]

    return client.checkout
      .addLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        setAddedProduct(product)
        setAddedProductData({quantity: quantity, price: price})
        setCheckout(res)
        setLoading(false)
        setDidJustAddToCart(true)
        // setTimeout(() => setDidJustAddToCart(false), 3000)
      })
  }

  const removeLineItem = (checkoutID, lineItemID, productName) => {
    setLoading(true)
    
    return client.checkout
    .removeLineItems(checkoutID, [lineItemID])
    .then((res) => {
        let newProductImages = {
            ...productImages,
        }
      
        delete newProductImages[productName]

        if (isBrowser) {
          localStorage.setItem(productImagesLocalStorageKey, JSON.stringify(newProductImages))
        }
      
        setProductImages(newProductImages)
        setCheckout(res)
        setLoading(false)
      })
  }

  const updateLineItem = (checkoutID, lineItemID, quantity) => {
    setLoading(true)

    const lineItemsToUpdate = [
      { id: lineItemID, quantity: parseInt(quantity, 10) },
    ]

    return client.checkout
      .updateLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        setCheckout(res)
        setLoading(false)
      })
  }

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        checkout,
        loading,
        didJustAddToCart,
        setDidJustAddToCart,
        addedProduct,
        setAddedProduct,
        addedProductData,
        setAddedProductData,
        productImages
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}
